import { Editor } from "react-draft-wysiwyg";
import SessionGuideLink from "../SessionGuideLink/SessionGuideLink";
import { Box } from "@mui/system";
import type { Guide, SessionGuideContent } from "../SessionGuides";
import { TextField } from "@mui/material";
import { Colors } from "utils/consts";
import { MdAddCircle } from "react-icons/md";
import { EditorState } from "draft-js";
import { useRef, forwardRef } from "react";

const toolbarOptions = ["inline", "textAlign", "remove", "history"];

interface GuideContent {
  guideContent: Guide;
  onContentChange: (content: Guide) => void;
  editorContent?: EditorState;
  onEditorChange: (editorState: EditorState) => void;
  handleSave: (obj: Guide) => void;
}

const SessionGuide = ({
  guideContent,
  editorContent,
  onEditorChange,
  onContentChange,
}: GuideContent) => {
  const handleDeleteLink = (linkId: number) => {
    if (guideContent) {
      const filteredLinks = guideContent.links.filter(
        (link) => link.id !== linkId
      );
      const obj = {
        ...guideContent,
        links: filteredLinks,
      };
      onContentChange(obj);
    }
  };

  const handleAddLink = () => {
    const obj: SessionGuideContent = {
      id: Math.random() * 100,
      title: "Enter title here",
      desc: "Paste your file / resource here",
    };

    onContentChange({
      ...guideContent,
      links: [...guideContent.links, obj],
    });
  };

  const handleLinkChange = (link: SessionGuideContent) => {
    const linkIndex = guideContent.links.findIndex((ele) => ele.id === link.id);
    const guideContentCopy = {
      ...guideContent,
      links: [...guideContent.links],
    };
    guideContentCopy.links[linkIndex] = link;
    onContentChange(guideContentCopy);
  };

  return (
    <Box padding={2}>
      {!guideContent && (
        <TextField
          size="small"
          sx={{
            input: { color: Colors.LightGrey },
            width: "100%",
            marginBottom: "10px",
          }}
          defaultValue="Enter content title here"
        />
      )}
      <Editor
        toolbar={{ options: toolbarOptions }}
        editorState={editorContent}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorChange}
      />
      {guideContent &&
        guideContent.links.map((guideLink, index) => (
          <SessionGuideLink
            id={guideLink.id}
            title={guideLink.title}
            desc={guideLink.desc}
            key={`session-guide-link-${index}`}
            click={handleDeleteLink}
            handleChange={handleLinkChange}
          />
        ))}
      <MdAddCircle
        size={30}
        color={Colors.Green}
        style={{ marginTop: "10px", cursor: "pointer" }}
        onClick={handleAddLink}
      />
    </Box>
  );
};

export default SessionGuide;
