import { Box, Button, Typography, Grid, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Colors } from "utils/consts";
import { MdClose, MdSave } from "react-icons/md";
import type { Guide } from "components/SessionGuides/SessionGuides";
import styles from "./SessionGuideModal.module.scss";
import SessionGuide from "components/SessionGuides/SessionGuide/SessionGuide";
import { ContentState, EditorState } from "draft-js";
import { useState, useRef } from "react";

export interface SimpleDialogProps {
  open?: boolean;
  onClose: () => void;
  header?: string;
  guideContent?: Guide | null;
}

const initialContent = {
  id: Math.random() * 100,
  title: "Enter content title here",
  desc: "",
  links: [],
};

const SessionGuideModal = ({
  open = false,
  onClose,
  header = "",
  guideContent,
}: SimpleDialogProps) => {
  const [editorState, setEditorState] = useState(
    guideContent
      ? EditorState.createWithContent(
          ContentState.createFromText(guideContent.desc)
        )
      : EditorState.createEmpty()
  );
  const [content, setContent] = useState<Guide>(guideContent ?? initialContent);

  const handleEditorChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const handleContentChange = (content: Guide) => {
    setContent(content);
  };

  const handleOnSave = () => {};

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding={3} className={styles.ModalHeader}>
        <Typography id="generic-modal-title" variant="h6" color={Colors.Green}>
          {content ? header : "Create new session guide"}
        </Typography>
        <MdClose
          color={Colors.Green}
          size={25}
          className={styles.CloseIcon}
          onClick={onClose}
        />
      </Box>

      <Box sx={{ overflowY: "auto" }}>
        <SessionGuide
          guideContent={content}
          editorContent={editorState}
          onEditorChange={handleEditorChange}
          handleSave={handleOnSave}
          onContentChange={handleContentChange}
        />
      </Box>

      <Divider light />
      <Grid container padding={3} justifyContent="flex-end">
        <Button
          variant="contained"
          color="success"
          endIcon={<MdSave />}
          onClick={handleOnSave}
        >
          Save
        </Button>
      </Grid>
    </Dialog>
  );
};

export default SessionGuideModal;
