import DataGrid from "components/Layout/DataGrid/DataGrid";
import Filters from "components/Layout/Filters/Filters";
import { dataGridFilters } from "mocks/dataGrid";

const MatchList = () => {
  return (
    <>
      <Filters filters={dataGridFilters} />
      <DataGrid />
    </>
  );
};

export default MatchList;
