export const sessionGuides = [
  {
    id: 1,
    title: "Session guide 1",
    desc: "Ad sit esse sunt nisi ea deserunt dolor eiusmod quis. Dolor sint amet cillum occaecat ullamco duis. Magna est sint aliquip id aute est sunt. Eiusmod ea ex commodo voluptate sit esse aliqua officia. Veniam aute ipsum est id ex incididunt voluptate sit.",
    links: [
      {
        id: 11,
        title: "Preparation checklist before next session",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-preparation-checklist.docx",
      },
      {
        id: 12,
        title: "Session agenda",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-agenda.docx",
      },
      {
        id: 13,
        title: "Follow up actions after the session",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-follow-up.docx",
      },
    ],
  },
  {
    id: 2,
    title: "Session guide 2",
    desc: "Ad sit esse sunt nisi ea deserunt dolor eiusmod quis. Dolor sint amet cillum occaecat ullamco duis. Magna est sint aliquip id aute est sunt. Eiusmod ea ex commodo voluptate sit esse aliqua officia. Veniam aute ipsum est id ex incididunt voluptate sit.",
    links: [
      {
        id: 21,
        title: "Preparation checklist before next session",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-preparation-checklist.docx",
      },
      {
        id: 22,
        title: "Session agenda",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-agenda.docx",
      },
      {
        id: 23,
        title: "Follow up actions after the session",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-follow-up.docx",
      },
    ],
  },
  {
    id: 3,
    title: "Session guide 3",
    desc: "Ad sit esse sunt nisi ea deserunt dolor eiusmod quis. Dolor sint amet cillum occaecat ullamco duis. Magna est sint aliquip id aute est sunt. Eiusmod ea ex commodo voluptate sit esse aliqua officia. Veniam aute ipsum est id ex incididunt voluptate sit.",
    links: [
      {
        id: 31,
        title: "Preparation checklist before next session",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-preparation-checklist.docx",
      },
      {
        id: 32,
        title: "Session agenda",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-agenda.docx",
      },
      {
        id: 33,
        title: "Follow up actions after the session",
        desc: "https://www.getwerkin.com/s/Merton-Mutual-Mentoring-Session-1-follow-up.docx",
      },
    ],
  },
];
