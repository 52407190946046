import React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { MdOutlineFilterList, MdChevronRight } from "react-icons/md";
import styles from "./Filters.module.scss";

type FilterItem = Record<string, string | number>;
export interface Filter {
  label: string;
  id: string;
  name: string;
  value: string;
  enabled?: boolean;
  items: FilterItem[];
}
interface FiltersProps {
  filters: Filter[];
  isOpen?: boolean;
  onChange?: (evt: SelectChangeEvent) => void;
}

const Filters = ({ filters, isOpen = false, onChange }: FiltersProps) => {
  const [filtersOpen, setFiltersOpen] = useState<boolean>(isOpen);

  const handleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  return (
    <Grid
      xs={12}
      container
      gap={2}
      alignItems="center"
      justifyContent="flex-end"
      pt={6}
      pb={0}
      pr={2}
      style={{ flexWrap: "nowrap" }}
    >
      <Button
        variant="contained"
        color="success"
        onClick={handleFilters}
        endIcon={
          !filtersOpen ? (
            <MdOutlineFilterList
              size={25}
              color="white"
              className={styles.Icon}
            />
          ) : (
            <MdChevronRight size={25} color="white" className={styles.Icon} />
          )
        }
      >
        {!filtersOpen ? "Filters" : "Close"}
      </Button>

      {filtersOpen && (
        <Grid
          container
          className={styles.FiltersWrapper}
          gap={2}
          xs={filters.length * 2}
        >
          {filters.map((filter: Filter) => (
            <FormControl
              size="small"
              className={styles.FormControl}
              key={filter.id}
            >
              <InputLabel color="success">{filter.label}</InputLabel>
              <Select
                onChange={onChange}
                labelId="demo-simple-select-label"
                id={filter.id}
                defaultValue={""}
                value={filter.value}
                label={filter.label}
                fullWidth
                name={filter.name}
                disabled={!filter.enabled}
              >
                {filter.items.length > 0
                  ? filter.items.map((item: FilterItem) => (
                      <MenuItem value={item.value} key={item.id}>
                        {item.label}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
export default Filters;
