import {
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import { Colors } from "utils/consts";

interface ConfirmationDialogProps {
  open: boolean;
  confirm: (e: React.MouseEvent<HTMLElement>) => void;
  cancel: () => void;
  text: string;
  data?: string | number;
}

const ConfirmationDialog = ({
  open,
  confirm,
  cancel,
  text,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm the action</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton>
          <MdOutlineClose onClick={cancel} />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" sx={{ color: Colors.DarkGrey }} onClick={cancel}>
          Cancel
        </Button>
        <Button color="success" variant="contained" onClick={confirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
