import { Paper, TextField } from "@mui/material";
import { MdDeleteForever } from "react-icons/md";
import { Colors } from "utils/consts";
import styles from "./SessionGuideLink.module.scss";
import React, { forwardRef } from "react";
import type { SessionGuideContent } from "../SessionGuides";

interface SessionGuideLinkProps {
  id: number;
  title: string;
  desc: string;
  handleChange: (link: SessionGuideContent) => void;
  click: (id: number) => void;
}

const SessionGuideLink = ({
  id,
  title,
  desc,
  handleChange,
  click,
}: SessionGuideLinkProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ id, title: e.target.value, desc });
  };

  return (
    <Paper elevation={3} className={styles.Wrapper}>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <TextField
            size="small"
            defaultValue={title}
            sx={{ input: { fontWeight: "bold" }, width: "100%" }}
            onChange={handleInputChange}
          />
        </div>

        <a className={styles.Link} href={desc}>
          {desc}
        </a>
      </div>
      <div>
        <MdDeleteForever
          color={Colors.Red}
          size={20}
          className={styles.DeleteIcon}
          onClick={() => click(id)}
        />
      </div>
    </Paper>
  );
};
export default SessionGuideLink;
