import {
  Alert,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Filters from "components/Layout/Filters/Filters";
import { sessionGuideFilters } from "mocks/dataGrid";
import { useEffect, useState } from "react";
import type { Filter } from "../Layout/Filters/Filters";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { MdAdd, MdDeleteForever } from "react-icons/md";
import { sessionGuides as sessionData } from "mocks/sessionGuides";
import { sliceString } from "utils/helpers";
import { Colors } from "utils/consts";
import image from "assets/img/session_guide.jpg";
import SessionGuideModal from "components/SessionGuideModal/SessionGuideModal";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import styles from "./SessionGuides.module.scss";

export type SessionGuideContent = {
  id: number;
  title: string;
  desc: string;
};
export interface Guide {
  id: number;
  title: string;
  desc: string;
  links: SessionGuideContent[];
}

const SessionGuides = () => {
  const [filters, setFilters] = useState<Filter[] | null>();
  const [sessionGuides, setSessionGuides] = useState<Guide[] | null>();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<Guide | null>(null);
  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean;
    data: number | string | undefined;
  } | null>(null);
  const [isCreate, setIsCreate] = useState(false);

  useEffect(() => {
    //fetch the filters and session guides from the BE
    setTimeout(() => {
      setFilters(sessionGuideFilters);
      setSessionGuides(sessionData);
    }, 1000);
  }, []);

  const handleChange = (evt: SelectChangeEvent) => {
    if (filters) {
      const mappedFilters = filters.map((filter) => {
        return filter.name === evt.target.name
          ? { ...filter, value: evt.target.value }
          : { ...filter, enabled: true };
      });
      setFilters(mappedFilters);
    }
  };

  const handleCardClick = (cardId: number) => {
    const sessionGuide = sessionGuides?.find((guide) => guide.id === cardId);
    if (sessionGuide) {
      setIsCreate(false);
      setSelectedCard(sessionGuide);
      setModalOpen(true);
    }
  };

  const handleCardDelete = () => {
    const filteredCards = sessionGuides?.filter(
      (card) => card.id !== confirmationDialog?.data
    );
    setSessionGuides(filteredCards);
    setConfirmationDialog({
      open: false,
      data: undefined,
    });
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleConfirmationOpen = (
    e: React.MouseEvent<SVGElement>,
    data: string | number
  ) => {
    e.stopPropagation();
    setConfirmationDialog({
      open: true,
      data,
    });
  };

  const handleConfirmationCancel = () => {
    setConfirmationDialog({
      open: false,
      data: undefined,
    });
  };

  const handleCreateSessionGuide = () => {
    setModalOpen(true);
    setIsCreate(true);
  };

  if (!filters) {
    return <div>Loading filters...</div>;
  }

  return (
    <>
      <Filters filters={filters} isOpen onChange={handleChange} />
      {!sessionGuides && (
        <Grid xs container flexDirection="row" justifyContent="center" mt={10}>
          <Alert severity="warning">
            Session Guides are associated with specific cohorts within
            collectives. Please choose your collective and cohort using the
            filters above.
          </Alert>
        </Grid>
      )}
      <Grid
        xs
        container
        justifyContent="flex-start"
        alignItems="center"
        gap={3}
        mt={5}
        padding={2}
      >
        {sessionGuides &&
          sessionGuides.map((sessionGuide) => (
            <Grid xs={3} key={sessionGuide.id}>
              <Card
                raised
                className={styles.Card}
                onClick={() => handleCardClick(sessionGuide.id)}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={image}
                  alt="session guide"
                />
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={styles.Titles}
                  >
                    {sessionGuide.title}
                  </Typography>
                  <p className={styles.Desc}>
                    {sliceString(sessionGuide.desc, 80)}
                  </p>
                  <p className={styles.Small}>4 links available</p>
                </CardContent>
                <MdDeleteForever
                  size={25}
                  color={Colors.Red}
                  className={styles.DeleteButton}
                  onClick={(e: React.MouseEvent<SVGElement>) =>
                    handleConfirmationOpen(e, sessionGuide.id)
                  }
                />
              </Card>
            </Grid>
          ))}

        {modalOpen && (
          <SessionGuideModal
            onClose={handleModalClose}
            open={modalOpen}
            {...(selectedCard ? { header: selectedCard.title } : null)}
            {...(isCreate ? null : { guideContent: selectedCard })}
          />
        )}

        {confirmationDialog?.open && (
          <ConfirmationDialog
            open={confirmationDialog.open}
            confirm={handleCardDelete}
            cancel={handleConfirmationCancel}
            text="Are you sure you want to delete this session guide?"
          />
        )}
        <Fab
          sx={{ background: Colors.Green }}
          aria-label="add"
          onClick={handleCreateSessionGuide}
        >
          <MdAdd size={30} color="white" />
        </Fab>
      </Grid>
    </>
  );
};

export default SessionGuides;
